import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from "react-router-dom";
import logo from './LogoGrande.png';
import Inicio from './pages/Inicio/Inicio'
import './App.css';

const inicio = React.lazy(() => import('./pages/Inicio/Inicio'));

class App extends React.Component {

  render() {

    console.log(this.props)
    return <Router>
        <Suspense fallback={<p> Loading...</p>}>
          <Switch>
            <Route exact path="/" component={App2} />
            <Route exact path="/inicio" component={inicio} />
          </Switch>
        </Suspense>
    </Router> 
  }
}

function App2() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Aplicación React en Construcción para Semillas Tropicales
        </p>
        <a
          className="App-link"
          href="http://semillastropicales.net/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Página actual Semillas Tropicales
        </a>
        <p> Plantándo árboles sembramos futuro y esperanza 🌱 </p>
      </header>
    </div>
  );
}

export default App;
