import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { withTheme } from 'evergreen-ui';

const NavContainer = styled.div`
  height: 40px;
  width: 100%;
  margin-top: 0;
  background-color: ${props => props.theme.palette.green.lightest} ;
  `

class Navigation extends React.Component {

  
  render() {
    console.log(this.props)
    return <NavContainer theme={this.props.theme}>
      NAV CONTAINER
    </NavContainer>
  //   return <Navbar variant="dark" className='App-header'>
  //     <Navbar.Brand href="/"><span role='img' aria-label="pine and a nut">🌲🌰</span> Online Tree Seeds Store <span role='img' aria-label="pine and a nut">🌲🌰</span></Navbar.Brand>
  //     <Nav className="mr-auto">
  //     </Nav>
  //     <Nav>
  //       <ButtonToolbar>
  //         <Button variant="light"><Link to="/home">HOME</Link></Button>
  //     <Button variant="light"><Link to={"/cart"}><span role='img' aria-label="cart">🛒 CART</span></Link></Button>
  //     <Button variant="light"><Link to={"/profile"} >PROFILE</Link></Button>        <Button variant="danger" onClick={this.handleLogoutClick}>LOG OUT</Button>
  //       </ButtonToolbar>
  //     </Nav>
  //   </Navbar>
  // }
  }
}
// export default withRouter(Navigation);
export default withTheme(Navigation);