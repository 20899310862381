import React from 'react';
import Navigation from '../../components/Navigation';
import { Heading } from 'evergreen-ui';
import styled from 'styled-components';
import bosque from '../../assets/bosque-de-pino1024x237.jpg';
import fachada from '../../assets/fachada768x432.jpg';
import logo from '../../assets/logo_principal.png';

const BosqueDePinos = styled.img`
  position: relative;
  width: 100%;
  top: 0px;
  filter: brightness(65%);
`

const Logo = styled.img`
  display: block;
  top: 0px;
`

const Section = styled.div`
  padding: 30px 0px;
`

const Fachada = styled.img`
  height: 50%;
  width: 50%;
`



class Inicio extends React.Component {
  
  render() {
    console.log(this.props)
    return (<div>
      <Navigation />
      <BosqueDePinos src={bosque} alt="Bosque de Pinos" />
      <Logo src={logo} alt="Logo Stro"/>
      <Section>

        <Heading size={600} color="green" > BIENVENIDOS </Heading>
        <Fachada src={fachada} alt="Fachada de Setro"/>
        <p> Al sitio oficial de Semillas Tropicales S. de R. L. en donde podrá conocer a fondo nuestra empresa, 
          aquí le presentamos todos nuestros productos y nuestro trabajo en el campo de las semillas, investigación 
          científica y experiencia en el campo de los cultivos de árboles maderables. También encontrará información 
          sobre nuestra labor social como empresa y una tienda donde podrá hacer sus pedidos online.rnGracias por visitarnos.</p>
      </Section>
    </div>)
  }
}

export default Inicio;